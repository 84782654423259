import React from "react";
import Layout from "../../components/Layout";

export const ThankYouPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          Thank you so much for your donation!, You are making the world a
          better place
        </div>
      </div>
    </section>
  </Layout>
);

export default ThankYouPage;
